import HeroImage from '../HeroImage/HeroImage';
import HeroText from '../HeroText/HeroText';
const defaultHeroTextContent = {
  titlePlain: 'Automated ads ',
  titleAnimated: 'for advisors',
  largeSubtitle:
    'Engage more prospects. Optimize digital marketing and supercharge content placement automatically.',
  smallSubtitle: 'Optimize ads on the web.',
  subSubtitle: 'Grow your book faster.',
};

const defaultHeroImageContent = {
  src: '/images/tearsheetads-hero.webp',
  alt: 'A happy, wealthy young woman ready to invest smiles through a cardboard Instagram post, ready to convert for a tearsheetads.com ad campaign.',
};

export default function Hero({heroContent}) {
  const hero =
    typeof heroContent !== 'undefined' && !!heroContent
      ? heroContent
      : {...defaultHeroTextContent, ...defaultHeroImageContent};
  return (
    <div className="pt-36 bg-blue-900 sm:pt-36 lg:pt-12 lg:pb-14 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="text-center lg:text-left lg:py-16">
              <HeroText {...hero} />
            </div>
          </div>
          <div className="lg:-mb-32 lg:-mt-16 lg:-mr-32 overflow-x-hidden">
            <HeroImage {...hero} />
          </div>
        </div>
      </div>
    </div>
  );
}
