import {navStyles} from './navStyles';
import Link from 'next/link';
import Logo from './Logo';
import BButton from '../BButton/BButton';
import {signIn, signOut} from 'next-auth/react';

function LoginButton({children}) {
  return (
    <div className="inline pr-4 md:pr-0">
      <button
        className="hidden md:inline-block min-w-36 max-w-48 px-3 py-2 mr-1 md:py-3 md:px-6 md:mr-4 uppercase leading-none text-center text-white font-thinner bg-black hover:bg-gray-600 hover:text-gray-100 rounded shadow"
        onClick={() => signIn()}>
        {children}
      </button>
      <button
        className="flex inline-flex md:hidden max-w-36 px-1 py-2 uppercase leading-none text-center text-black font-thinner bg-white hover:text-blue-800 text-sm"
        onClick={() => signIn()}>
        {children}
      </button>
    </div>
  );
}
function SignupButton({children}) {
  return (
    <button
      className="inline-flex min-w-36 px-3 py-2 max-w-48 md:py-3 md:px-6 md:mr-2 uppercase leading-none text-white font-thinner bg-blue-800 hover:bg-blue-900 rounded shadow"
      onClick={() => signIn()}>
      {children}
    </button>
  );
}
function TeamEmailButton({teamUrl}) {
  const teamEmail = 'support@tearsheetads.com';
  return (
    <>

    </>
  );
}
function LogoutButton() {
  return (
    <button className="" onClick={() => signOut()}>
      Log out
    </button>
  );
}
export default function NavbarSimple({logoSrc, user}) {
  if (!logoSrc) return <></>;
  const {navUrl, signInUrl, signUpUrl, teamUrl} = {
    navUrl: 'https://tearsheetads.com',
    signInUrl: '/login',
    signUpUrl: '/signup',
    teamUrl:
      'mailto:team@tearsheetads.com?&subject=Ad%20campaign&body=Start%20a%20new%20campaign',
  };

  return (
    <nav
      className="fixed flex inline-flex items-center justify-between pl-0 p-2 md:p-4 bg-white max-h-24"
      style={navStyles}
      id="navbar"
      name="navbar">
      <div className="flex items-center justify-center align-center">
        <Logo src={logoSrc} />
      </div>
      <div className="md:pr-1">
        {!user ? (
          <>
            <div className="text-right pt-1 md:hidden">
              <LoginButton signInUrl={signInUrl}>Log in</LoginButton>
              <SignupButton>Sign up</SignupButton>
            </div>
            <div className="hidden md:inline-flex">
              <LoginButton signInUrl={signInUrl}>Log in</LoginButton>
              <SignupButton>Sign up</SignupButton>
            </div>
          </>
        ) : (
          <div className="inline-flex">
            <TeamEmailButton teamUrl={teamUrl} />
            <BButton user={user} logoSrc={logoSrc} shape="square" />
          </div>
        )}
      </div>
    </nav>
  );
}
