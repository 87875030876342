/* eslint-disable no-unused-vars */
/* TODO: Remove eslint disable no-unused-vars */
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

export default function Logo({src}) {
  const alt = 'tearsheetads.com logo. Conquer ads online and satiate your compliance requirements.';
  return (<>
    <div
      className="flex flex-shrink-0 inline-flex "
      id="logo"
      name="logo">
      <Link href="/">
        <a className="text-xl text-black font-semibold h-12 w-12" href="/">
          <Image
            priority={true}
            className=""
            src={src}
            alt={
              alt
            }
            height={200}
            width={200}
          />
        </a>
      </Link>

    </div>
    <span className="text-3xl font-itim pl-1 text-transparent bg-clip-text bg-gradient-to-br from-black to-blue-700">tearsheetads</span>
  </>

  );
}
