import Image from 'next/image';
import heroImage from '../../public/images/tearsheetads-hero-landscape.webp';

export default function HeroImage({src, alt}) {
  //return <Image src={src} alt={alt} width={1980} height={1281} />;
  return <div className="mt-12 h-72 md:h-96 md:mt-12 lg:object-cover lg:h-full bg-yellow-400">
    <Image
      src={heroImage}
      alt={alt}
      height={1281}
      width={1038}
      layout='responsive'
      priority
    />
    <div
      aria-hidden="true"
      className="absolute inset-0"
      style={{mixBlendMode: 'multiply'}
      }></div >
  </div >
}

