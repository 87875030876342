import {useState} from 'react';

export default function RangeInput() {
  const [rangeval, setRangeval] = useState(250);
  return (
    <div className="mt-3">
      <label
        htmlFor="budget"
        className="block text-sm font-medium text-gray-700">
        {`Budget: $${rangeval}`}
      </label>
      <div className="mt-1 rounded-md">
        <input
          className="block w-full pl-16 sm:pl-14 sm:text-sm border-black bg-blue-700 focus:ring-blue-700 text-black black"
          type="range"
          name="budget"
          id="budget"
          defaultValue={rangeval}
          min={100}
          max={10000}
          step={100}
          onChange={(event) => setRangeval(event.target.value)}
        />
      </div>
    </div>
  );
}
