export default function HeroText({
  titlePlain,
  titleAnimated,
  largeSubtitle,
  smallSubtitle,
  subSubtitle,
}) {
  let subSubtitleArray =
    typeof subSubtitle !== 'undefined' && !!subSubtitle
      ? subSubtitle.split(' ')
      : 'Grow and succeed faster.'.split(' ');
  if (subSubtitleArray.length < 4)
    subSubtitleArray = 'Grow and succeed faster.'.split(' ');

  return (
    <>
      <h1 className="mt-4 text-5xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
        <span className="block">{titlePlain}</span>
        <span className="animate-pulse bg-clip-text text-transparent bg-gradient-to-r from-yellow-100 to-yellow-800 block">
          {titleAnimated}
        </span>
      </h1>
      <p className="hidden md:block mt-3 text-base text-blue-200 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
        {largeSubtitle}
      </p>
      <p className="md:hidden mt-3 text-base text-blue-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
        {smallSubtitle}{' '}
      </p>
      <p className="mt-1 text-base text-blue-100 sm:mt-2 sm:text-lg lg:text-md xl:text-lg">
        <strong>{subSubtitleArray[0] + ' '}</strong> {subSubtitleArray[1] + ' '}{' '}
        <strong>{subSubtitleArray[2] + ' '}</strong> {' ' + subSubtitleArray[3]}
      </p>
    </>
  );
}
