import Link from 'next/link';
import Image from 'next/image';
import React from 'react';
import {signIn, signOut} from 'next-auth/react';
import Logo from '../Navbar/Logo';

export default function Footer({props, user}) {
  if (!props) return <></>;
  const longDescription = '';
  return (
    <nav id="footer" name="footer">
      {!user ? (
        <div className="text-right py-2 px-4">
          <button
            className="transform hover:scale-105 lg:inline-block mr-6 lg:mt-0 text-sm text-black hover:text-blue-700"
            onClick={() => signIn()}>
            Log in
          </button>
          <button
            className="transform hover:scale-105 lg:inline-block lg:mt-0 text-sm text-black hover:text-blue-700"
            onClick={() => signIn()}>
            Sign up
          </button>
        </div>
      ) : (
        <div className="text-right py-2 px-4">
          <button
            className="transform hover:scale-105 lg:inline-block lg:mt-0 text-sm text-black hover:text-blue-700"
            onClick={() => signOut()}>
            Log out
          </button>
        </div>
      )}
      <div className="flex flex-wrap items-start justify-between xl:justify-center p-4 pb-0 border-t">
        <div className="flex lg:w-auto ">
          <div className="w-full lg:block lg:w-1/2 lg:pr-5 py-5 text-sm ">
            <div className="hidden md:flex w-full -ml-1 md:w-1/2 items-center align-center">
              <Logo src={props.logoSrc} />
            </div>
            <div className="text-left text-gray-800 pt-3">
              <p className="mt-3">
                <Link href='/'><a className="font-semibold hover:underline">tearsheetads.com</a></Link> is solving challenging last mile problems for financial services through boutique software and product-first financial innovation.
              </p>
              <p className="mt-3">
                <Link href='/'><a className="font-semibold hover:underline">tearsheetads.com</a></Link> tools empower financial advisors, financial planners, wealth managers, and asset managers to expand their client book.
              </p>
              <p className="mt-3">Ad algorithms leverage much of the same math and stats, engineering, and techniques that are used to invest money throughout financial services.
              </p>
            </div>
          </div>
          <FooterLinks {...props} user={user} />
        </div>
      </div>

      <div className="inline-flex lg:w-2/3 pt-auto align-middle content-center items-end">
        <PrivacyFooterLinks {...props} />
      </div>
    </nav>
  );
}

function FooterLinks({support, socials, user, ctaButtonText}) {
  let buttonText = ctaButtonText || 'Run ads';
  if (!support || !socials) return <></>;
  return (
    <div className="flex flex-wrap w-full lg:w-1/2 lg:inline-flex md:justify-between">
      <div className="w-full md:w-auto md:pl-8 lg:pl-4 text-right md:text-left md:mr-4 pt-6">
        <div className="text-black text-md uppercase">Support</div>
        <FooterItems obj={support} />
      </div>
      <div className="w-full text-right md:w-auto md:pl-8 lg:pl-4 md:text-left md:mr-4 md:mt-0 md:pt-6">
        <div className="text-black text-md uppercase">Socials</div>
        <FooterItems obj={socials} isSocialLink={true} />
      </div>
      <div className="pl-1 w-full text-right md:w-auto md:block md:-mt-10 md:pr-0 lg:mt-0 lg:pt-6 md:pl-8 lg:pl-16">
        {!user ? (
          <button
            className="transform hover:scale-105 py-4 px-6 leading-none font-bold text-white bg-blue-700 hover:bg-blue-900 rounded-md shadow"
            onClick={() => signIn()}>
            {buttonText}
          </button>
        ) : null}
      </div>
    </div>
  );
}
function PrivacyFooterLinks({legal, copyright}) {
  if (!legal || !copyright) return <></>;
  return (
    <div className="inline-flex py-4 px-4">
      <Link key="copyright" href="https://tincre.com">
        <a
          className="leading-loose font-thin block text-sm text-black hover:text-blue-700 mx-1"
          href="https://tincre.com"
          key="copyright">
          {copyright}
        </a>
      </Link>
      <PrivacyFooterItems obj={legal} />
    </div>
  );
}
function PrivacyFooterItems({obj}) {
  const results = Object.entries(obj).map(([displayText, slug]) => (
    <Link key={slug} href={slug}>
      <a
        className="leading-loose font-thin block text-sm text-black hover:text-blue-700 mx-1"
        href={slug}
        key={slug}>
        {displayText}
      </a>
    </Link>
  ));
  return results;
}

/**This solves issue #29 https://github.com/musicfox/b00st.com/issues/29 **/
function adjustFooterSlug(slug) {
  const linkedInUrl = 'https://www.linkedin.com/products/musicfox-b00st/';
  const twitterUrl = 'https://twitter.com/b00st_ads';
  const facebookUrl = 'https://www.facebook.com/B00STads';
  const instagramUrl = 'https://www.instagram.com/b00st_ads/';
  const documentationUrl = 'https://docs.musicfox.io/b00st';
  if (slug === 'https://www.linkedin.com/company/musicfox/') {
    return linkedInUrl;
  }
  if (slug === 'https://twitter.com/musicfoxinc') {
    return twitterUrl;
  }
  if (slug === 'https://www.facebook.com/Musicfox.io') {
    return facebookUrl;
  }
  if (slug === 'https://www.instagram.com/musicfox.io/') {
    return instagramUrl;
  }
  if (slug === 'https://docs.musicfox.io') {
    return documentationUrl;
  }
  return slug;
}

function FooterItems({obj, isSocialLink}) {
  // console.log(JSON.stringify(obj));
  const results = Object.entries(obj).map(([displayText, slug]) => {
    const newSlug = adjustFooterSlug(slug);
    if (isSocialLink) {
      return (
        <a
          className="flex leading-loose my-4 block text-sm text-gray-700 hover:text-blue-700 transform hover:scale-110"
          href={newSlug}
          key={newSlug}
          rel="noopener noreferrer">
          <div className="ml-auto md:m-auto text-center relative h-8 w-8">
            <Image
              className="hover:text-blue-800"
              layout="fill"
              alt={displayText}
              src={`/images/${displayText.toLowerCase()}.svg`}
            />
          </div>
        </a>
      );
    }
    return displayText !== 'Artist Platform' ? (
      <a
        className="leading-loose block text-sm text-gray-700 hover:text-blue-700 pt-4 transform hover:scale-105"
        href={newSlug !== '/team' ? newSlug : 'https://musicfox.io/team'}
        key={newSlug !== '/team' ? newSlug : 'https://musicfox.io/team'}
        rel="noopener noreferrer"
        target={newSlug !== '/team' ? '' : '_blank'}>
        {' '}
        {displayText}{' '}
      </a>
    ) : null;
  });
  return results;
}

export {FooterItems, PrivacyFooterItems, PrivacyFooterLinks, FooterLinks};
