import NavbarSimple from '../Navbar/NavbarSimple';
import Footer from '../Footer/Footer';
import Head from 'next/head';
import {seoDefaults} from '../../seo-config';

export default function Layout({navbarContent, footerProps, user, children}) {
  const {title, description, imageUrl} = seoDefaults;
  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#2b5797" />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content="#ffffff" />
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />{' '}
        <meta name="description" content={description} />
        <meta
          name="author"
          content="tearsheetads.com, by Tincre"
        />
        <title>{title}</title>
        <meta property="og:image" content={imageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://tearsheetads.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:domain" content="tearsheetads.com" />
        <meta name="twitter:url" content="https://tearsheetads.com" />
        <meta name="twitter:image" content={imageUrl} />
      </Head>

      <div id="index__body" className="bg-body bg-gray-100 text-body font-body">
        <NavbarSimple {...navbarContent} user={user} />
        <div className="relative overflow-hidden">{children}</div>
      </div>
      <Footer props={footerProps} user={user} />
    </>
  );
}
