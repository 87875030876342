export function FirstWord({word}) {
  return (
    <span className="animate-pulse bg-clip-text text-transparent bg-gradient-to-b from-blue-100 to-blue-400 group-hover:from-blue-400 group-hover:to-blue-900 block">
      {word}
    </span>
  );
}

export function SecondWord({word}) {
  return (
    <span className="animate-pulse bg-clip-text text-transparent bg-gradient-to-b from-blue-300 to-blue-600 group-hover:from-blue-400 group-hover:to-blue-900 block">
      {word}
    </span>
  );
}

export function ThirdWord({word}) {
  return (
    <span className="animate-pulse bg-clip-text text-transparent bg-gradient-to-b from-blue-600 to-blue-700 group-hover:from-blue-700 group-hover:to-blue-900 block">
      {word}
    </span>
  );
}
export default function ThreeWords({words}) {
  if (typeof words === 'undefined') words = ['Real', 'Easy', 'Ads!'];
  let [word1, word2, word3] = words;
  return (
    <>
      <FirstWord word={word1} />
      <SecondWord word={word2} />
      <ThirdWord word={word3} />
    </>
  );
}
