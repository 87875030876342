/* eslint-disable no-unused-vars */

// import { useRef, useState, useEffect, MutableRefObject } from 'react';
import fetch from 'isomorphic-unfetch';
import jwtDecode from 'jwt-decode';

import { IMAGE_EXTENSIONS, VIDEO_EXTENSIONS } from './constants';

/**
 * A fetcher for useSwr
 *
 * @param uri string web endpoint
 * @param options object `fetch` options. See fetch documentation.
 * @returns JSON response
 */
const fetcher = async (uri, options) => {
  let fetchOptions = options;
  if (typeof options !== 'object') fetchOptions = {};
  const response = await fetch(uri, fetchOptions); // TODO can we just send an undefined
  // param and avoid the options nonsense?
  return response.json();
};

/**
 * Decode a JWT token client side
 *
 * @param token string jwt (base64) encoded token
 * @returns decoded string token
 */
function clientJwtDecode(token) {
  if (!token)
    throw new Error(
      'Token must be defined or not null. Do better next time, thanks.'
    );
  return jwtDecode(token); // Returns with the JwtPayload type
}

/**detectMediaType
 * Detects whether the given URL is a '"video"', '"image"', and otherwise,
 * returns `null`.
 *
 * @param url A string url with a file extension.
 * @returns A string, one of `"video"` or `"image"`, or null.
 */
function detectMediaType(url) {
  if (typeof url !== 'string') return null;
  const extension = url
    .split('/')
    .slice(-1)[0]
    .split('.')
    .slice(-1)[0]
    .toLowerCase();
  if (IMAGE_EXTENSIONS.includes(extension)) return 'image';
  if (VIDEO_EXTENSIONS.includes(extension)) return 'video';
  return null;
}

/**
 * Hook usehooks.com/useHover
 * *Usage*
 * ```JavaScript
 * const Component: FC = () => {
 *   const [hoverRef, isHovered] = useHover<HTMLDivElement>()
 *   return (
 *     <div ref={hoverRef}>
 *       {`The current div is ${isHovered ? `hovered` : `unhovered`}`}
 *     </div>
 *   )
 * }
 * ```
 */

// Hook
// function useHover<T extends HTMLElement = HTMLElement>(): [
//  MutableRefObject<T>?,
//  boolean?
//] {
//  const [value, setValue] = useState<boolean>(false);
//  const ref = useRef<T>(null);
//  const handleMouseOver = () => setValue(true);
//  const handleMouseOut = () => setValue(false);
//  useEffect(
//    // eslint-disable-next-line consistent-return
//    () => {
//      const node = ref.current;
//      if (node) {
//        node.addEventListener('mouseover', handleMouseOver);
//        node.addEventListener('mouseout', handleMouseOut);
//        return () => {
//          node.removeEventListener('mouseover', handleMouseOver);
//          node.removeEventListener('mouseout', handleMouseOut);
//        };
//      }
//    },
//    // eslint-disable-next-line react-hooks/exhaustive-deps
//    [ref.current] // Recall only if ref changes
//  );
//  return [ref as MutableRefObject<T>, !!value];
//}

/* BlockRender is a serializer for the sanity BlockContent component.
 * It should be fed into the BlockContent component as ```{types: { block:
 * BlockRenderer }}```.
 */
const BlockRenderer = (props) => {
  // const setTailwindHeadingClass = (level) => {
  //  let defaultClassStyles = '';
  //  if (level == 1) {
  //    return 'text-4xl' + defaultClassStyles;
  //  }
  //  if (level == 2) {
  //    return 'text-3xl' + defaultClassStyles;
  //  }
  //  if (level == 3) {
  //    return 'text-2xl' + defaultClassStyles;
  //  }
  //  if (level == 4) {
  //    return 'text-xl' + defaultClassStyles;
  //  }
  //  if (level == 5) {
  //    return 'text-md font-bold leading-tight' + defaultClassStyles;
  //  }
  //  if (level == 6) {
  //    return 'text-md leading-none' + defaultClassStyles;
  //  }
  //  return '';
  //};
  // const { textStyle = 'normal' } = props.node;

  // if (/^h\d/.test(textStyle)) {
  //  const level = textStyle.replace(/[^\d]/g, '');
  //  return React.createElement(
  //    textStyle,
  //    { className: setTailwindHeadingClass(level) },
  //    props.children
  //  );
  //}

  // if (textStyle === 'blockquote') {
  //  return <blockquote>- {props.children}</blockquote>;
  //}
  // if (textStyle === 'normal') {
  return <p className="mb-2">{props.children} </p>;
};

// Fall back to default handling
export { BlockRenderer, clientJwtDecode, fetcher, detectMediaType };
