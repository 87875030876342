/* eslint-disable no-unused-vars, jsx-a11y/anchor-is-valid */

import {useSession} from 'next-auth/react';
import {useState, useEffect} from 'react';
import useSwr, {mutate} from 'swr';
import localforage from 'localforage';
import Layout from '../components/Layout/Layout';
import Hero from '../components/PageSections/Hero';
import {getNavbarContent, getFooterContent} from '../lib/api';
import {fetcher, clientJwtDecode} from '../lib/utils';
import fetch from 'isomorphic-unfetch';
import dynamic from 'next/dynamic';

const SupportInfo = dynamic(() =>
  import('../components/PageSections/SupportInfo')
);

const VisionQuote = dynamic(() =>
  import('../components/PageSections/VisionQuote')
);

const FeatureOverview = dynamic(() =>
  import('../components/PageSections/FeatureOverview')
);

const DeepDiveContent = dynamic(() =>
  import('../components/PageSections/DeepDiveContent')
);

const DemoVideo = dynamic(() => import('../components/PageSections/DemoVideo'));

export default function Index({
  navbarContent,
  logoCloudContent,
  heroContent,
  demoVideoContent,
  featureOverviewContent,
  deepDiveContent,
  visionQuoteContent,
  supportInfoContent,
  footerProps,
}) {
  const [state, setState] = useState(null);
  const [decoded, setDecoded] = useState(null);
  const {data: session, status} = useSession();
  const loading = status === 'loading';

  const {data, error} = useSwr(
    '/api/session',
    {data: {sessionId: state?.sessionId}},
    fetcher
  );
  // Update the user session via SWR on change
  useEffect(() => {
    if (typeof state?.sessionId !== 'undefined') {
      mutate('/api/session', {sessionId: state?.sessionId}, true);
    }
  }, [state]);
  // Update w/user email if not present in state
  useEffect(() => {
    if (
      typeof state?.sessionId !== 'undefined' &&
      !state?.email &&
      typeof session?.user !== 'undefined' &&
      !loading &&
      typeof session?.user?.email !== 'undefined'
    ) {
      fetch('/api/session', {
        method: 'PUT',
        body: JSON.stringify({
          sessionId: state?.sessionId || null,
          email: session.user?.email,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => (res.ok ? res.json() : {}))
        .catch((error) =>
          console.error(`Error in updating user email ${error.message}`)
        );
    }
  }, [state, loading, session?.user]);

  // Create a new session
  useEffect(() => {
    // lookup local storage first
    localforage.getItem('tearsheetads.com').then((stateObj) => {
      if (!stateObj) {
        // create initial state in db using session identifier
        fetch('/api/session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((res) => res.json())
          .then((data) => {
            const token = data?.token;
            if (typeof token !== 'undefined') {
              const decoded = clientJwtDecode(token);
              setDecoded(decoded);
              setState({'tearsheetads.com': decoded});
            }
          })
          .catch((error) => console.error(error.message));
      } else {
        setState(stateObj);
      }
    });
  }, []);

  // Update outer state data when we update state via setAppData
  useEffect(() => {
    if (decoded) {
      setState(decoded);
    }
  }, [decoded]);

  // Set local state on state object changes
  useEffect(() => {
    if (state) {
      localforage
        .setItem('tearsheetads.com', state)
        .then(() => {})
        .catch((error) => console.error(error.message));
    }
  }, [state]);

  // Update the local state for decoded tokens on change of data from swr
  useEffect(() => {
    if (typeof data !== 'undefined') {
      const token = data?.token;
      if (typeof token !== 'undefined') {
        setDecoded(clientJwtDecode(token));
      }
    }
  }, [data, error]);
  // TODO map logos in landingLogoCloudContent to [{url: '', alt: ''}, ] objects
  return (
    <Layout
      navbarContent={navbarContent}
      footerProps={footerProps}
      user={session?.user}>
      <Hero heroContent={heroContent} />
      <DemoVideo user={session?.user} demoVideoContent={demoVideoContent} />
      <FeatureOverview featureOverviewContent={featureOverviewContent} />
      <VisionQuote visionQuoteContent={visionQuoteContent} />
      <DeepDiveContent deepDiveContent={deepDiveContent} />
      <SupportInfo supportInfoContent={supportInfoContent} />
    </Layout>
  );
}

export async function getStaticProps() {
  let nc = await getNavbarContent();
  nc.logoSrc = '/images/tearsheetads-logo-square.webp';
  const footerProps = {...(await getFooterContent()), logoSrc: nc?.logoSrc};
  return {
    props: {
      navbarContent: nc,
      logoCloudContent: null,
      heroContent: null,
      demoVideoContent: null,
      featureOverviewContent: null,
      deepDiveContent: null,
      visionQuoteContent: null,
      supportInfoContent: null,
      footerProps: footerProps,
    },
  };
}
